
















import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

@Component
export default class AddressDetails extends Vue {
  @Prop({ required: true }) zipcode !: string;
  @Prop({ required: true }) street !: string;
  @Prop({ required: true }) number !: number;
  @Prop({ required: true }) neighborhood !: string;
  @Prop({ required: true }) city !: string;
  @Prop({ required: true }) state !: string;

  get address() {
    return `${this.street}, N° ${this.number}, ${this.neighborhood}, ${this.city}, ${this.state}`;
  }

  get formatZipcode() {
    return `${this.zipcode.substring(0, 5)}-${this.zipcode.substring(5)}`;
  }
}
