





















import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--neutralcolor-low-pure)';

@Component
export default class Copy extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
}
