
























































import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

import Copy from '@/assets/icons/Copy.vue';

import { PaymentSuccess } from '../../interfaces/IPayment';

import { getDataSeparatedFromDateTime } from '@/share/Util/Dates';

@Component({
  components: {
    Copy,
  },
  filters: {
    formattedDate(date: string) {
      if (!date) return '';

      const {
        day, month, year, hours, minutes,
      } = getDataSeparatedFromDateTime(date);

      return `${day}/${month}/${year} às ${hours}:${minutes}`;
    },
  },
})
export default class PurchaseDetails extends Vue {
  @Prop({ required: true }) payment !: PaymentSuccess;

  get discount() {
    return this.payment.product.price - this.payment.payment.amount;
  }
}
