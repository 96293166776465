










































import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class ModalCanceledPayment extends Vue {
  handleClickCanceled() {
    this.$emit('cancel');
  }
}
