









import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component
export default class BannerPayment extends Vue {
  @Prop() backgroundColor!: string;

}
