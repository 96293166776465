












import { Component, Vue, Prop } from 'vue-property-decorator';

import { METHOD_BY_BILLET, METHOD_BY_PIX } from '../../constants';

@Component({
  filters: {
    formatMethod(method: string) {
      if (!method) return '';

      if (method === METHOD_BY_PIX) return 'PIX';

      if (method === METHOD_BY_BILLET) return 'Boleto';

      return 'Cartão de crédito';
    },
  },
})
export default class MethodsPayment extends Vue {
  @Prop({ default: 'pix' }) name!: string;
}
